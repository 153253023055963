<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-full">
        <div class="help-text">
          {{ $t('roof.help_text') }}
        </div>
      </div>
    </div>
    <stepNav :name="$t('roof.pick_rooftype')" :validStep="isStepComplete(['rooftype'])" />
    <optionItems :options="options" :multiselect="false" selection="rooftype"></optionItems>

    <div class="form-group">
      <radioButtonGroup
        :label="$t('roof.underlag')"
        :options="roofBases"
        v-model="roofBase"
      />
    </div>
  </div>
</template>

<script>
import optionItems from '@/components/optionItems';
import stepValidation from '../../mixins/stepValidation';

export default {
  name: 'chooseRoof',
  data: function() {
    return {
      roofBases: ["Råspont med papp", "Lätt underlagstak", "Lätt underlagstak utan ströläkt"],
    };
  },
  mixins: [stepValidation],
  computed: {
    options() {
      return this.$store.getters.getRoofTypes;
    },
    roofType: {
      get() {
        return this.$store.getters.getSelectedValue('rooftype');
      },
      set(value) {
        this.$store.dispatch('updateValue', { name: 'rooftype', value: value });
      },
    },
    roofBase: {
      get() {return this.$store.getters.getSelectedValue('roofBase')},
      set(value) {this.$store.dispatch('updateValue', {name: 'roofBase', value: value})}
    },
  },
  methods: {
    goToPrevStep() {
      this.$store.dispatch('previousStep');
    },
    goToNextStep() {
      this.$store.dispatch('nextStep');
    },
  },
  components: {
    stepNav: () => import('../stepNav'),
    optionItems,
    radioButtonGroup: () => import("../radioButtonGroup"),
  },
};
</script>

<style scoped>
.help-text {
  text-align: center;
}
</style>
